//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BottomFooter',
  data() {
    return {
      socialLinks: [
        {
          link: 'https://www.facebook.com/fightcamp/',
          ariaLabel: 'FightCamp Facebook Page',
          icon: 'facebook-f',
        },
        {
          link: 'https://www.instagram.com/fightcamp/',
          ariaLabel: 'FightCamp Instagram Profile',
          icon: 'instagram',
        },
        {
          link: 'https://www.youtube.com/channel/UCJ6J1r5u8CFsbyVZ03A6IaQ/',
          ariaLabel: 'FightCamp Youtube Channel',
          icon: 'youtube',
        },
        {
          link: 'https://www.pinterest.com/JoinFightCamp/',
          ariaLabel: 'FightCamp Pinterest Page',
          icon: 'pinterest',
        },
        {
          link: 'https://www.tiktok.com/@fightcamp',
          ariaLabel: 'FightCamp TikTok Page',
          icon: 'tiktok',
        },
      ],
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    getLinkName(name) {
      return `BottomFooter_${name}`;
    },
  },
};
